@use '@dashboard-experience/mastodon/lib/theme.scss';

#mastodon {
  .mastodon-progress-step.cds--progress-step--complete,
  .mastodon-progress-step.cds--progress-step--current {
    .cds--progress-line {
      background-color: theme.$ui-aqua-600;
    }
  }
  li.cds--progress-step--complete,
  li.cds--progress-step--current {
    svg {
      fill: theme.$ui-aqua-600;
    }
    .cds--progress-label {
      color: theme.$ui-aqua-600;
    }
  }

  li.cds--progress-step--incomplete{
    svg {
      fill: theme.$ui-grey-300;
    }
  }
}
